<template>
  <div class="introduce-box">
    <div class="title">CRM管理</div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.introduce-box {
  width: 500px;
  height: 300px;
}
.title {
  width: 300px;
  height: 65px;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 18px;
}
.row {
  display: flex;
  height: 100px;
  &>div{
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.svg svg {
  font-size: 48px;
}
.desc {
  margin-left: 5px;
  div {
    font-size: 20px;
    font-weight: bold;
  }
  text-align: left;
  font-size: 14px;
}
</style>
