<template>
  <div class="dashboard-container">
    <MainView />
  </div>
</template>

<script>
import MainView from '@/views/login/components/MainView'

export default {
  name: 'Dashboard',
  components: {MainView },
  data() {
    return {
      currentRole: 'adminDashboard'
    }
  },
  computed: {
  },
  created() {
  }
}
</script>
<style lang="scss" scoped>
.dashboard-container {
  height: calc(100vh - 85px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: url("/images/login-pic.png");
  background-size: cover;
}
</style>
